import { BrowserRouter, Routes, Route } from "react-router-dom";

import { lazy, Suspense } from "react";

const Welcome = lazy(() => import("./pages/Welcome"));
const Success = lazy(() => import("./pages/Success"));
const Welcome2 = lazy(() => import("./pages/Welcome/index2"))

const ROUTES = [
  {
    path: "/",
    element: (
      <Suspense>
        <Welcome2 />
      </Suspense>
    ),
  },
  {
    path: "/webpayplus/redirect",
    element: (
      <Suspense>
        <Success />
      </Suspense>
    ),
  },
  {
    path: "/new",
    element: (
      <Suspense>
        <Welcome2 />
      </Suspense>
    ),
  },
];

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
